import {HStack, Text, Image, Link, Button, Container} from "@chakra-ui/react";
// import WithFriendsLogo from "../assets/companies/withfriends.svg";
import MedPlum from "../assets/companies/medplum.svg"
import Mistral7b from "../assets/companies/mistral7b.svg";
import StableXL from "../assets/companies/stableXL.svg";
import GradioLogo from "../assets/companies/gradio.svg";
import GoogleGemini from "../assets/companies/GoogleGemini.svg";
import ChatGPT4 from "../assets/companies/chatgpt4.svg";
import PoweredBy from "../assets/poweredBy.svg";

const data = [
    {logo: GoogleGemini, href: "https://gemini.google.com"},
    {logo: ChatGPT4, href: "https://openai.com"},
    {logo: Mistral7b, href: "https://mistral.ai/"},
    {logo: StableXL, href: "https://stablediffusionxl.com"}
];

export default function Users() {
    return (
        <>
            <Text fontSize="5xl" mb={12} mt={32}>
                Built on the latest state-of-the-art AI technologies
            </Text>
            <Image
                src={PoweredBy}
                alt="placeholder"
                width={{base: "100%"}}
                maxW={"5xl"}
                mx={"auto"}
                transition="filter 0.3s linear"
                _hover={{filter: "none"}}
            />
            <HStack spacing={4} justifyContent="center" mb={32} flexWrap="wrap" pt={"0 !important"}
                    p={{base: 20, md: "initial"}}>
                {data.map(({logo, href, ...props}) => (
                    <Link href={href} isExternal key={logo}>
                        <Image
                            src={logo}
                            alt="placeholder"
                            mx={4}
                            width={{base: "250px", md: "150px"}}
                            mb={{base: 8, md: 0}}
                            filter={href === "https://llamahub.ai" ? "grayscale(100%)" : "grayscale(100%) invert(1)"}
                            transition="filter 0.3s linear"
                            _hover={{filter: "none"}}
                            {...props}
                        />
                    </Link>
                ))}
            </HStack>

        </>
    )
}
