import {EmailIcon, HamburgerIcon} from "@chakra-ui/icons";
import {
    Box,
    Button,
    ButtonGroup,
    Flex,
    HStack,
    IconButton,
    Image,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    useBreakpointValue,
} from "@chakra-ui/react";
import {Link} from "react-router-dom";
import {FaDiscord, FaGithub, FaTwitter, FaNewspaper, FaBook, FaLock, FaBlog, FaTerminal, FaUsers} from "react-icons/fa";
import {IoMdPeople} from "react-icons/io";
import logo from "../assets/icon.png";

export default function NavBar() {
    const listDisplay = useBreakpointValue({base: "none", lg: "flex"});
    const menuDisplay = useBreakpointValue({base: "flex", lg: "none"});
    const navItems = [
        // {
        //   label: "Twitter",
        //   icon: <FaTwitter />,
        //   link: "https://twitter.com/sweep__ai",
        // },
        {
            label: "Github",
            icon: <FaGithub/>,
            link: "https://github.com/genaivn",
            target: "_blank"
        },
        // {
        //   label: "Discord",
        //   icon: <FaDiscord />,
        //   link: "https://discord.gg/sweep",
        // },
        {
            label: "Email",
            icon: <EmailIcon/>,
            link: "mailto:support@genaivn.com",
            target: "_blank"
        },
        {
            label: "Newsletter",
            icon: <FaNewspaper/>,
            link: "/signup",
            target: ""
        },
        {
            label: "CLI",
            icon: <FaTerminal/>,
            link: "https://docs.genaivn.com",
            target: "_blank"
        }
    ];

    const extItems = [
        {
            label: "Documentation",
            icon: <FaBook/>,
            link: "https://docs.genaivn.com",
            target: "_blank"
        },
        {
            label: "About us",
            icon: <IoMdPeople/>,
            link: "/about-us",
            target: ""
        },
        {
            label: "Blogs",
            icon: <FaBlog/>,
            link: "/blogs",
            target: ""
        },
        // {
        //   label: "Community",
        //   icon: <FaUsers />,
        //   link: "https://community.sweep.dev/",
        // },
        {
            label: "Security",
            icon: <FaLock/>,
            link: "/security",
            target: ""
        },
        // {
        //   label: "Privacy",
        //   icon: <FaUserSecret />,
        //   link: "/privacy.pdf",
        // }
    ]

    return (
        <Box as="nav" bg="bg-surface" boxShadow="sm" width="full" p={4}>
            <HStack spacing="10" justify="space-between">
                <Flex justify="space-between" flex="1">
                    <HStack>
                        <Button as={Link} variant="ghost" to="/">
                            <Image src={logo} alt="logo" width={10} borderRadius={12}/>
                            GenAI VN
                        </Button>
                        {extItems.map((item) => {
                            return (
                                <Button
                                    as="a"
                                    variant="ghost"
                                    href={item.link}
                                    target={item.target}
                                    display={listDisplay}
                                >
                                    {item.label}
                                </Button>
                            )
                        })}
                    </HStack>
                    <ButtonGroup variant="link" display={listDisplay}>
                        {navItems.map((item) => (
                            <IconButton
                                key={item.label}
                                as="a"
                                href={item.link}
                                target={item.target}
                                icon={item.icon}
                                variant="ghost"
                                aria-label={item.label}
                                px={2}
                            />
                        ))}
                        {/* Added PricingModal to always be displayed */}
                        <Link to="/pricing">
                            <Button variant="ghost">Pricing</Button>
                        </Link>
                    </ButtonGroup>
                    <Menu>
                        <MenuButton
                            as={IconButton}
                            aria-label="Options"
                            icon={<HamburgerIcon/>}
                            variant="outline"
                            display={menuDisplay}
                        />
                        <MenuList backgroundColor="#333">
                            {[...extItems, ...navItems].map((item) => (
                                <MenuItem backgroundColor="#333">
                                    {item.label !== "Buy Sweep Pro" && (
                                        <IconButton
                                            key={item.label}
                                            icon={item.icon}
                                            variant="ghost"
                                            aria-label={item.label}
                                            onClick={() => {
                                                window.open(item.link, "_blank");
                                            }}
                                        />
                                    )}
                                    {item.label}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Menu>
                </Flex>
            </HStack>
        </Box>
    );
}
