import { Box, Link, Stack } from "@chakra-ui/react";
import YCLogo from "../assets/yc.webp";

export default function Footer() {
  return (
    <Box as="footer" py={4}>
      <Stack
        direction={{ base: "column", md: "row" }}
        spacing={4}
        align="center"
        justify="center"
        fontSize={"sm"}
        my={{ base: 4, md: 0 }}
      >
        <Link
          href="https://github.com/genaivn"
          textAlign={{ base: "center", md: "left" }}
        >
          Github
        </Link>
        <Link
          href="https://docs.genaivn.com"
          textAlign={{ base: "center", md: "left" }}
        >
          Docs
        </Link>
        <Link
          href="/pricing"
          textAlign={{ base: "center", md: "left" }}
        >
          Pricing
        </Link>
        <Link
          href="/privacy.pdf"
          textAlign={{ base: "center", md: "left" }}
        >
          Privacy
        </Link>
        <Link
          href="/tos.pdf"
          textAlign={{ base: "center", md: "left" }}
        >
          Terms of Services
        </Link>
        <p className="transparentText">© 2024 GenAI VN, Inc.</p>
      </Stack>
    </Box>
  );
}
