import {Box, Flex, Text} from "@chakra-ui/react";

import ExternalLinkWithText from "./ExternalLinkWithText";
import User from "./User";

type TestimonialProps = {
    children: React.ReactNode,
    name: string,
    company: string,
    href: string
}

const Testimonial = ({children, name, company, href}: TestimonialProps): JSX.Element => {
    return (
        <Box
            overflow="hidden"
            p={4}
            mb={16}
            width={{base: "100%", md: "30%"}}
            backgroundColor="purple.900"
            borderRadius="10px"
            borderColor="purple.300"
            borderWidth={1}
        >
            <Flex width="100%" m={2} mb={4}>
                <User><Text color="white">{name.split(' ').map(n => n[0]).join('').toUpperCase()}</Text></User>
                <Box flexGrow={1} textAlign="left" ml={4}>
                    <Text fontWeight="bold">{name}</Text>
                    <ExternalLinkWithText href={href} color="#aaa">{company}</ExternalLinkWithText>
                </Box>
            </Flex>
            <Text fontSize="md" textAlign="left">
                {children}
            </Text>
        </Box>
    );
}

export default function Testimonials() {
    return (
        <Box display="flex" justifyContent="center" alignItems="center">
            <Box m={8} mt={32} width={{base: "100%", md: "80%"}}>
                <Text fontSize="3xl" mb={12} textAlign="center">What our users are saying</Text>
                <Box display="flex" flexWrap="wrap" justifyContent="space-between">
                    <Testimonial name="Mark Thompson" company="Chairman and CEO of CNN" href="https://edition.cnn.com/">
                        I was hesitant at first about the Chatbot App's sleek interface allows me to access multiple AI
                        models in one interface. It's convenient and affordable. I've never seen any other app offering
                        this before.
                    </Testimonial>
                    <Testimonial name="Ashaya Sharma" company="Co-founder + CTO at Honeycomb.ai"
                                 href="https://honeycomb.ai">
                        Pro Tools represents the ultimate all-in-one solution for analyzing documents. It provides
                        unparalleled convenience by offering the capability to analyze PDFs, YouTube videos, and
                        webpages.
                    </Testimonial>
                    <Testimonial name="Eduardo Reis" company="AI @ Stanford AIMI" href="https://plugnplai.com">
                        I've tried various AI models individually, but none of them could match the level of convenience
                        and efficiency the Chatbot App offers. Having access to ChatGPT, GPT-4, and Google PaLM 2 all in
                        one place is a game-changer! 🙌
                    </Testimonial>
                    <Testimonial name="Sagar Patil" company="Product Manager, SSL Zen" href="https://sslzen.com">
                        As someone who relies heavily on AI for my work, the Chatbot App is a lifesaver. No more
                        switching between different platforms or paying separate subscriptions. This all-in-one solution
                        has made my workflow so much smoother.
                    </Testimonial>
                    <Testimonial name="Kunal Gupta" company="CEO of Withfriends" href="https://withfriends.co">
                        Its intuitive interface and advanced algorithms save me time and energy, enabling me to
                        concentrate on other aspects of my work.
                    </Testimonial>
                    <Testimonial name="Jeremy Evans" company="Co-founder + CTO at savvy" href="https://trysavvy.com">
                        It's a great time-saver to access all the latest AI models through a single, simple interface.
                        There's no need to switch between tools.
                    </Testimonial>
                </Box>
            </Box>
        </Box>
    );
}
