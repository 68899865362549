import {
    Flex,
    Container,
    Heading,
    Stack,
    Text,
    Button,
} from "@chakra-ui/react";
import {tsParticles} from "tsparticles";
import {loadConfettiPreset} from "tsparticles-preset-confetti";
import {FaDiscord, FaGithub} from "react-icons/fa";
import {useState} from "react";
import logo from "../assets/icon.png";

import ExternalLinkWithText from "./ExternalLinkWithText";
import {TypeAnimation} from "react-type-animation";

const genai_chatbot = require("../assets/genaivn-chatbot.mp4");

export default function CallToAction() {
    const [spin, setSpin] = useState(false);
    // const canvas = document.getElementById('canvas3d');
    // const app = new Application(canvas);
    // app.load('https://prod.spline.design/jzV1MbbHCyCmMG7u/scene.splinecode');
    return (
        <Container maxW={"5xl"}>
            <Stack
                textAlign={"center"}
                align={"center"}
                spacing={{base: 8, md: 10}}
                py={{base: 4, md: 15}}
                style={{paddingTop: "0 !important"}}
                mb={36}
            >
                <img
                    src={logo}
                    alt="Logo"
                    style={{
                        width: "100px",
                        animation: spin
                            ? "spin 0.5s linear"
                            : "bob 0.75s ease-in-out infinite alternate",
                        marginTop: "-2rem !important",
                        marginBottom: "30px",
                    }}
                    onClick={async () => {
                        setSpin(!spin);
                        await loadConfettiPreset(tsParticles);
                        await tsParticles.load("tsparticles", {
                            preset: "confetti",
                            particles: {
                                color: {
                                    value: ["#800080", "#FFFFFF"],
                                },
                            },
                        });
                    }}
                />
                {/* <img src={logo} alt="Logo" width={120} height={120} style={{
          animation: "bob 0.75s ease-in-out infinite alternate",
        }} /> */}
                {/* <style>
          {`
            @keyframes bob {
              from {
                transform: translateY(0);
              }
              to {
                transform: translateY(15px);
              }
            }
            @keyframes spin {
              from {
                transform: rotate(0deg) scale(1);
              }
              to {
                transform: rotate(360deg);
              }
            }
          `}
        </style> */}
                <Heading
                    fontWeight={600}
                    fontSize={{base: "3xl", sm: "4xl", md: "5xl"}}
                    lineHeight={"110%"}
                    mt="0 !important"
                >
                    GenAI VN: <TypeAnimation
                    sequence={[
                        'Access Google Gemini, ChatGPT, and GPT-4 with the Chatbot App\'s intuitive interface',
                        4000,
                        'Enjoy unlimited usage without daily limits',
                        4000,
                        "Simplify your experience with powerful tools",
                        4000,
                    ]}
                    wrapper="span"
                    speed={80}
                    repeat={Infinity}
                    cursor={false}
                />
                    {/* &nbsp;my&nbsp; */}
                    {/* <TypeAnimation
            sequence={[
              "data pipeline",
              4000,
              'flask app',
              4000,
              'ML pipeline',
              4000,
            ]}
            wrapper="span"
            speed={50}
            repeat={Infinity}
            cursor={false}
          /> */}
                </Heading>
                <Text
                    color={"purple.400"}
                    maxW={"3xl"}
                    mt="1rem !important"
                    mb="1rem !important"
                >
                    To start using AI App on the web, mobile, and desktop, simply click or tap the 'Get Started' button.
                </Text>
                <Button
                    color="white"
                    p={6}
                    colorScheme={"purple"}
                    bg={"purple.400"}
                    _hover={{bg: "purple.600"}}
                    onClick={() => window.open("https://chat.genaivn.com")}
                    fontSize={"xl"}
                    mb="1rem !important"
                >
                    <FaGithub/>
                    &nbsp;&nbsp;Get Started
                </Button>
                <ExternalLinkWithText
                    href="https://chat.genaivn.com" // updated link
                    color="purple.400"
                    mt="0 !important"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <FaDiscord/>
                    &nbsp;&nbsp;Join our Team
                </ExternalLinkWithText>
                <Flex w={"full"} mt="4rem !important">
                    <Container width="100vw" boxShadow="0 0 80px #181818" p={0} maxWidth="full">
                        <video style={{borderRadius: "10px"}} src={genai_chatbot} autoPlay muted loop playsInline>
                            Your browser does not support the video tag.
                        </video>
                    </Container>
                </Flex>
            </Stack>
        </Container>
    );
}
